import Vue from 'vue'
import VueRouter from 'vue-router'
const Home =  () => import('@/views/Home')
const Error =  () => import('@/views/Error')
Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    component: Home,
    children: []
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
