import Vue from 'vue'

const SectionTitle = () => import('@/components/SectionTitle')
const ProductListItem = () => import('@/components/ProductListItem')
const AppForm = () => import('@/components/AppForm')
const AppFormInput = () => import('@/components/form-inputs/AppFormInput')
const AppCreditCardInput = () => import('@/components/form-inputs/AppCreditCardInput')
const AppSelect = () => import('@/components/form-inputs/AppSelect')
const OrderTotals = () => import('@/components/ui/OrderTotals')
const VTextField = () => import('vuetify/lib/components/VTextField')
const VSelect = () => import('vuetify/lib/components/VSelect')
const VTextarea = () => import('vuetify/lib/components/VTextarea')
import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective)

Vue.component('VTextField', VTextField)
Vue.component('VSelect', VSelect)
Vue.component('VTextarea', VTextarea)
Vue.component('AppForm', AppForm)
Vue.component('AppFormInput', AppFormInput)
Vue.component('AppSelect', AppSelect)
Vue.component('AppCreditCardInput', AppCreditCardInput)
Vue.component('SectionTitle', SectionTitle)
Vue.component('ProductListItem', ProductListItem)
Vue.component('OrderTotals', OrderTotals)
