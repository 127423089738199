<template>
  <v-footer color="app-black" height="49" fixed>
    <v-container class="py-0">
      <div class="d-flex align-center flex-wrap justify-center">
        <span class="d-block">powered by</span>

        <div>
          <v-btn link target="_blank" elevation="0" depressed plain color="transparent" href="https://puresol.io">
            <v-img width="97.5" contain src="/puresol-logo.svg"/>
          </v-btn>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang="scss">
span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
  color: #C7BBB3;
  margin-right: 25px;
}
</style>
