<template>
  <v-app>
    <router-view></router-view>
    <AppFooter></AppFooter>
  </v-app>
</template>

<script>
import AppFooter from "./components/AppFooter";
export default {
  name: "App",
  components: { AppFooter },
};
</script>
