import { baseURL } from '@/config'
import axios from 'axios'

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    app: "public",
  }
})

export default instance