import {get} from 'lodash'
export const globalCommandsMixins = {
  methods: {
    getProp(item, path, defaultValue) {
      return get(item, path, defaultValue)
    },
  },

  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
