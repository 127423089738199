import Vue from 'vue'
import Vuex from 'vuex'
const countries = require('@/data/countries.json')
Vue.use(Vuex)
/*
Snackbar Data Example
* {
      color: 'success',
      data: `Item added to cart`,
      props: {top: true, right: true, timeout: 6000000},
      action: {title: 'Go To Cart', props: {to: {name: 'checkout-payment'}}}
    }
* */
export default new Vuex.Store({
  state: () => ({
    snackbar: false,
    alert: false,//{timeout: 6000000000, type: 'error'},
    countries
  }),

  getters: {
    snackbar: state => state.snackbar,
    alert: state => state.alert,
    countries: state => state.countries
  },
  mutations: {
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload
    },
    SET_ALERT(state, payload) {
      state.alert = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
