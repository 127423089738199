import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
require("@/plugins/componentImports");
import router from "./router";
import store from "./store";
import VeeValidate, { Validator } from "vee-validate";
import validateLuhn from "@/library/validateLuhn";
import en from "vee-validate/dist/locale/en";
import { globalCommandsMixins } from "@/mixins/globalCommandsMixins";
import axios from "@/plugins/axios";

import moment from "moment";

Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios

Vue.use(VeeValidate);
Validator.localize("en", en);
Validator.extend("luhn", {
  // getMessage: (field, params, data) => {
  getMessage: () => {
    return "Incorrect card number";
  },
  validate: (value) => validateLuhn(value),
});
Validator.extend('zipCode', {
  message: () => 'The {_field_} field must be a valid zip code.',
  validate: (value) => {
    const regex = /^\d{5}(?:[-\s]\d{4})?$/;
    return regex.test(value);
  }
});
Vue.mixin(globalCommandsMixins);
Vue.config.productionTip = false;

import VueMask from 'v-mask'
Vue.use(VueMask);

import "@/sass/app.scss";
import "@/sass/icons.scss";
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
