import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#F0F4F8',
        'app-grey': '#F9F9F9',
        'dark-grey': '#E9E9E9',
        error: '#942A2A',
        'error-lighten': '#FFF3F3',
        success: '#70AF60',
        'success-lighten': '#EBFEE6',
        'app-black': '#2E3038'
      }
    }
  }
});
